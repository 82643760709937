<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' ||
        $store.state.fastPermissoes.professor == 'S') &&
        $store.state.fastPermissoes.ativo == 'S'
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              >Modo professor</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Perguntas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
    </div>
    <div class="fd-mode-adm-content mt-4">
      <div class="container">
        <div class="row">
          <div
            class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
          >
            <!-- fd-app-welcome -->
            <section class="fd-app-welcome text-left">
              <h1 class="aluno_font_color">
                Agenda do
                <span>professor</span>
              </h1>
              <img
                :src="require('@/assets/images/form-effect-01.png')"
                style="max-height: 35px"
              >
            </section>
            <!-- /fd-app-welcome -->
          </div>
        </div>
      </div>
      <!-- conteudo principal -->
      <section class="container">
        <!-- Calendário de disponibilidade -->
        <div class="row mt-4">
          <div class="col-12">
            <div class="card mb-2 ">
              <div class="card-header bg-ligth text-dark">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <i class="fas fa-info-circle text-secondary mr-1" /> Calendário de disponibilidade
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                    <button
                      class="btn btn-sm btn-primary ml-1 mr-1"
                      @click="showModal('modalOpcoesRapidas')"
                    >
                      <small><i class="fas fa-cog" /> Opções rápidas</small>
                    </button> 
                    <button
                      class="btn btn-sm btn-primary ml-1 mr-1"
                      @click="showModal('modalOpcoesAvancadas')"
                    >
                      <small><i class="fas fa-cogs" /> Opções avançadas</small>
                    </button> 
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- Opções de data -->
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div id="date-calendar">
                      <FunctionalCalendar
                        :configs="calendarConfigs"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row">
                      <div
                        id="date-horarios"
                        class="col-12"
                      >
                        <p class="mt-2">
                          <b>Horários</b>
                          <u
                            style="cursor: pointer"
                            class="text-info ml-2"
                            onclick="marcaTodosHorarios('horarios-professor', 'marcar')"
                          >Marcar todos</u>
                          <u
                            style="cursor: pointer"
                            class="text-info ml-2"
                            onclick="marcaTodosHorarios('horarios-professor', 'desmarcar')"
                          >Desmarcar todos</u>
                        </p>
                        <div id="select-horas-calendario">
                          <label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="0"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >00:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="1"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >01:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="2"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >02:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="3"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >03:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="4"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >04:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="5"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >05:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="6"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >06:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="7"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >07:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="8"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >08:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="9"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >09:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="10"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >10:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="11"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >11:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="12"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                            style="color: red"
                          >12:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="13"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >13:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="14"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >14:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="15"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >15:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="16"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >16:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="17"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >17:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="18"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >18:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="19"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >19:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="20"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >20:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="21"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >21:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="22"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >22:00</span></label><label
                            class="horarios-professor mr-4 mt-4 mb-4"
                          ><input
                            type="checkbox"
                            value="23"
                          ><span
                            title="Marque os horários"
                            class="checkmark text-center pt-2"
                          >23:00</span></label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-12">
                        <div id="UpdatePanelbtnAtualizaCalendarioDisponivel">
                          <a
                            id="btnAtualizaCalendarioDisponivel"
                            onclick="atualizaCalendario('disponivel', this.id);"
                            href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnAtualizaCalendarioDisponivel','')"
                          >
                            <span
                              id="btnDisponivelAnimation"
                              class="btn btn-success btn-sm mt-2"
                            ><small>Disponibilizar</small>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="col-12">
                        <div id="UpdatePanelbtnAtualizaCalendarioIndisponivel">
                          <a
                            id="btnAtualizaCalendarioIndisponivel"
                            onclick="atualizaCalendario('indisponivel', this.id);"
                            href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnAtualizaCalendarioIndisponivel','')"
                          >
                            <span
                              id="btnIndisponivelAnimation"
                              class="btn btn-danger btn-sm mt-2"
                            >
                              <small>Indisponibilizar</small>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- / Opções de data -->
                </div>              
              </div>
            </div>
          </div>
        </div>
        <!-- /Calendário de disponibilidade -->
      </section>
      <!-- conteudo principal -->

      <div class="container">
        <div class="mt-5 mb-5">
          <a
            class="btn btn-red-hollow"
            :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
          >
            <b-icon-arrow-return-left />
            Voltar
          </a>
        </div>
      </div>
    </div>

    <!-- modals -->
    <modal
      name="modalOpcoesRapidas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Opções rápidas</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalOpcoesRapidas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <!-- Ações rapidas -->
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 p-4">
              <p class="mb-2">
                <a
                  href="#"
                  class="text-danger"
                  data-toggle="modal"
                  data-target="#modal-ano-indisponibilizado"
                ><i class="far fa-calendar-times mr-1" /> Indisponibilizar
                  ano de <span class="ano_indisponibilizado">2021</span></a>
              </p>
              <div
                id="modal-ano-indisponibilizado"
                class="modal con-modal"
                tabindex="-1"
                role="dialog"
              >
                <div
                  class="modal-dialog modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="con-modal-body text-center">
                        <h3>
                          Tem certeza que você deseja indisponibilizar todo o
                          ano de
                          <span class="h3 ano_indisponibilizado">2021</span>?
                        </h3>
                        <input
                          id="hdnAnoIndisponibilizado"
                          type="hidden"
                          name="ctl00$ContentPlaceHolder1$hdnAnoIndisponibilizado"
                          value="2021"
                        >
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <p>
                              Todos os dias desse ano serão marcados como
                              indisponível!
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div id="UpdatePanelbtnIndisponibilizarAno">
                              <a
                                id="btnIndisponibilizarAno"
                                href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnIndisponibilizarAno','')"
                              >
                                <span
                                  id="btnConfirmarIndisponibilizarAno"
                                  class="btn btn-danger btn-sm"
                                >Confirmar</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mb-2">
                <a
                  href="#"
                  class="text-danger"
                  data-toggle="modal"
                  data-target="#modal-mes-indisponibilizado"
                ><i class="far fa-calendar-times mr-1" /> Indisponibilizar
                  mês de
                  <span class="mes_indisponibilizado">janeiro 2021</span></a>
              </p>
              <div
                id="modal-mes-indisponibilizado"
                class="modal con-modal"
                tabindex="-1"
                role="dialog"
              >
                <div
                  class="modal-dialog modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="con-modal-body text-center">
                        <h3>
                          Tem certeza que você deseja indisponibilizar o mês
                          de
                          <span
                            class="h3 mes_indisponibilizado"
                          >janeiro 2021</span>?
                        </h3>
                        <input
                          id="hdnMesIndisponibilizado"
                          type="hidden"
                          name="ctl00$ContentPlaceHolder1$hdnMesIndisponibilizado"
                          value="1"
                        >
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <p>
                              Todos os dias desse mês serão marcados como
                              indisponível!
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div id="UpdatePanelbtnIndisponibilizarMes">
                              <a
                                id="btnIndisponibilizarMes"
                                href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnIndisponibilizarMes','')"
                              >
                                <span
                                  id="btnConfirmarIndisponibilizarMes"
                                  class="btn btn-danger btn-sm"
                                >Confirmar</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mb-2">
                <a
                  href="#"
                  class="text-danger"
                  data-toggle="modal"
                  data-target="#modal-dia-indisponibilizado"
                ><i class="far fa-calendar-times mr-1" /> Indisponibilizar
                  <span
                    class="dia_indisponibilizado"
                  >toda Quarta-feira de 2021</span></a>
              </p>
              <div
                id="modal-dia-indisponibilizado"
                class="modal con-modal"
                tabindex="-1"
                role="dialog"
              >
                <div
                  class="modal-dialog modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="con-modal-body text-center">
                        <h3>
                          Tem certeza que você deseja Indisponibilizar
                          <span
                            class="h3 dia_indisponibilizado"
                          >toda Quarta-feira de 2021</span>?
                        </h3>
                        <input
                          id="hdnDiaSemanaIndisponibilizado"
                          type="hidden"
                          name="ctl00$ContentPlaceHolder1$hdnDiaSemanaIndisponibilizado"
                          value="3"
                        >
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <p>
                              Todos os dias desse ano serão marcados como
                              indisponível!
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div
                              id="UpdatePanelbtnIndisponibilizarDiasSemana"
                            >
                              <a
                                id="btnIndisponibilizarDiasSemana"
                                href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnIndisponibilizarDiasSemana','')"
                              >
                                <span
                                  id="btnConfirmarIndisponibilizarDiasSemana"
                                  class="btn btn-danger btn-sm"
                                >Confirmar</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mb-2">
                <a
                  href="#"
                  class="text-success"
                  data-toggle="modal"
                  data-target="#modal-ano-disponibilizado"
                ><i class="far fa-calendar-plus mr-1" /> Disponibilizar
                  ano de <span class="ano_disponibilizado">2021</span></a>
              </p>
              <div
                id="modal-ano-disponibilizado"
                class="modal con-modal"
                tabindex="-1"
                role="dialog"
              >
                <div
                  class="modal-dialog modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="con-modal-body text-center">
                        <h3>
                          Tem certeza que você deseja Disponibilizar todo o
                          ano de
                          <span class="h3 ano_disponibilizado">2021</span>?
                        </h3>
                        <input
                          id="hdnAnoDisponibilizado"
                          type="hidden"
                          name="ctl00$ContentPlaceHolder1$hdnAnoDisponibilizado"
                          value="2021"
                        >
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <p>
                              Todos os dias desse ano serão marcados como
                              Disponível!
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div id="UpdatePanelbtnDisponibilizarAno">
                              <a
                                id="btnDisponibilizarAno"
                                href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnDisponibilizarAno','')"
                              >
                                <span
                                  id="btnConfirmarDisponibilizarAno"
                                  class="btn btn-success btn-sm"
                                >Confirmar</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mb-2">
                <a
                  href="#"
                  class="text-success"
                  data-toggle="modal"
                  data-target="#modal-mes-disponibilizado"
                ><i class="far fa-calendar-plus mr-1" /> Disponibilizar
                  todo o mês de
                  <span class="mes_disponibilizado">janeiro 2021</span></a>
              </p>
              <div
                id="modal-mes-disponibilizado"
                class="modal con-modal"
                tabindex="-1"
                role="dialog"
              >
                <div
                  class="modal-dialog modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="con-modal-body text-center">
                        <h3>
                          Tem certeza que você deseja Disponibilizar o mês de
                          <span
                            class="h3 mes_disponibilizado"
                          >janeiro 2021</span>?
                        </h3>
                        <input
                          id="hdnMesDisponibilizado"
                          type="hidden"
                          name="ctl00$ContentPlaceHolder1$hdnMesDisponibilizado"
                          value="1"
                        >
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <p>
                              Todos os dias desse mês serão marcados como
                              disponível!
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div id="UpdatePanelbtnDisponibilizarMes">
                              <a
                                id="btnDisponibilizarMes"
                                href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnDisponibilizarMes','')"
                              >
                                <span
                                  id="btnConfirmarDisponibilizarMes"
                                  class="btn btn-success btn-sm"
                                >Confirmar</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mb-2">
                <a
                  href="#"
                  class="text-success"
                  data-toggle="modal"
                  data-target="#modal-dia-disponibilizado"
                ><i class="far fa-calendar-plus mr-1" /> Disponibilizar
                  <span
                    class="dia_disponibilizado"
                  >toda Quarta-feira de 2021</span></a>
              </p>
              <div
                id="modal-dia-disponibilizado"
                class="modal con-modal"
                tabindex="-1"
                role="dialog"
              >
                <div
                  class="modal-dialog modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="con-modal-body text-center">
                        <h3>
                          Tem certeza que você deseja Disponibilizar
                          <span
                            class="h3 dia_disponibilizado"
                          >toda Quarta-feira de 2021</span>?
                        </h3>
                        <input
                          id="hdnDiaSemanaDisponibilizado"
                          type="hidden"
                          name="ctl00$ContentPlaceHolder1$hdnDiaSemanaDisponibilizado"
                          value="3"
                        >
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <p>
                              Todos os dias desse ano serão marcados como
                              disponível!
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div id="UpdatePanelbtnDisponibilizarDiasSemana">
                              <a
                                id="btnDisponibilizarDiasSemana"
                                href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$btnDisponibilizarDiasSemana','')"
                              >
                                <span
                                  id="btnConfirmarDisponibilizarDiasSemana"
                                  class="btn btn-success btn-sm"
                                >Confirmar</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Ações rapidas -->
        </div>
      </div>
    </modal>

    <modal
      name="modalOpcoesAvancadas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Opções avançadas</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalOpcoesAvancadas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <!-- Ações avançadas -->
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 p-4">
              <div
                id="div_opcoes_avancadas"
                class="row"
              >
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 mt-4">
                  <p><b>Mês</b></p>
                  <select
                    id="mes-opcao-avancada"
                    class="form-control form-control-block mt-4"
                  >
                    <option value="0">
                      Todos
                    </option>
                    <option value="1">
                      Janeiro
                    </option>
                    <option value="2">
                      Fevereiro
                    </option>
                    <option value="3">
                      Março
                    </option>
                    <option value="4">
                      Abril
                    </option>
                    <option value="5">
                      Maio
                    </option>
                    <option value="6">
                      Junho
                    </option>
                    <option value="7">
                      Julho
                    </option>
                    <option value="8">
                      Agosto
                    </option>
                    <option value="9">
                      Setembro
                    </option>
                    <option value="10">
                      Outubro
                    </option>
                    <option value="11">
                      Novembro
                    </option>
                    <option value="12">
                      Dezembro
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 mt-4">
                  <p><b>Dia</b></p>
                  <select
                    id="dia-opcao-avancada"
                    class="form-control form-control-block mt-4"
                  >
                    <option value="1">
                      Toda Segunda-Feira
                    </option>
                    <option value="2">
                      Toda Terça-Feira
                    </option>
                    <option value="3">
                      Toda Quarta-Feira
                    </option>
                    <option value="4">
                      Toda Quinta-Feira
                    </option>
                    <option value="5">
                      Toda Sexta-Feira
                    </option>
                    <option value="6">
                      Todo Sábado
                    </option>
                    <option value="0">
                      Todo Domingo
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 mt-4">
                  <p><b>Disponibilidade</b></p>
                  <select
                    id="disponibilidade-opcao-avancada"
                    class="form-control form-control-block mt-4"
                  >
                    <option value="1">
                      Estarei disponível
                    </option>
                    <option value="0">
                      Estarei indisponível
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-4 mt-2">
                  <div id="date-horarios2">
                    <p>
                      <b>Horários</b>
                      <u
                        style="cursor: pointer"
                        class="text-info ml-4"
                        onclick="marcaTodosHorarios('horarios-professor', 'marcar')"
                      >Marcar todos</u>
                      <u
                        style="cursor: pointer"
                        class="text-info ml-4"
                        onclick="marcaTodosHorarios('horarios-professor', 'desmarcar')"
                      >Desmarcar todos</u>
                    </p>
                    <div id="select-horas-calendario-avancado">
                      <label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="0"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >00:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="1"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >01:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="2"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >02:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="3"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >03:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="4"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >04:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="5"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >05:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="6"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >06:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="7"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >07:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="8"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >08:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="9"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >09:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="10"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >10:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="11"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >11:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="12"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >12:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="13"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >13:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="14"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >14:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="15"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >15:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="16"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >16:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="17"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >17:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="18"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >18:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="19"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >19:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="20"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >20:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="21"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >21:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="22"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >22:00</span></label><label
                        class="horarios-professor mr-4 mt-4 mb-4"
                      ><input
                        type="checkbox"
                        value="23"
                      ><span
                        title="Marque os horários"
                        class="checkmark text-center pt-2"
                      >23:00</span></label>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-4">
                  <button class="btn btn-sm btn-primary">
                    <small>Confirmar configuração</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- /Ações avançadas -->
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { FunctionalCalendar } from "vue-functional-calendar";

export default {
  name: "HomeInternoProfessorAgenda",
  components: {
    FunctionalCalendar,
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Calendário
      calendarData: {},
      calendarConfigs: {
        sundayStart: true,
        dateFormat: "dd/mm/yyyy",
        isDatePicker: true,
        isDateRange: false,
        markedDates: [],
      },
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style scope>
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
/* CSS do calendario */
.horarios-professor .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 50px;
  background-color: #eee;
  font-weight: normal;
}
.horarios-professor,
.horarios-professor {
  position: relative;
  display: block;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  float: left;
}
.horarios-professor input,
.horarios-professor input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.horarios-professor input:checked ~ .checkmark,
.horarios-professor input:checked ~ .checkmark {
  background-color: #2196f3;
}
</style>
